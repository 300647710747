import { render, staticRenderFns } from "./EditInnTourGroupItems.vue?vue&type=template&id=203a4bbc&scoped=true&"
import script from "./EditInnTourGroupItems.vue?vue&type=script&lang=js&"
export * from "./EditInnTourGroupItems.vue?vue&type=script&lang=js&"
import style0 from "./EditInnTourGroupItems.vue?vue&type=style&index=0&id=203a4bbc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../app/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "203a4bbc",
  null
  
)

export default component.exports